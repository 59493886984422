<template>
    <a-layout id="components-layout-demo-top-side">

        <a-layout-header class="header">
            <Header id="header"  @search="onSearch">

            </Header>
        </a-layout-header>

        <a-layout-content style="margin-top: 0.42rem;box-sizing: border-box; padding-bottom:0.6rem;background: #000000;">

            <a-layout style="flex-direction: row;justify-content: space-between; width:100%;background: transparent">
                <a-layout-content>
                <div style="width: 100%;position: relative;">
                 <!--   <video style="width: 100%;position: relative;"   ref="videoPlayer" preload="auto"  x-webkit-airplay webkit-playsinline loop="loop" controls="controls" x5-video-player-type="h5" x5-video-player-fullscreen="true" x5-video-orientation="portraint" playsinline="true" :poster="playerOptions.poster" :src="playerOptions.sources[0].src"></video>-->
                    <video-player  style="width: 100%;height: 100%;"  class="video-player vjs-custom-skin"
                                   ref="videoPlayer"
                                   :playsinline="true"
                                   :options="playerOptions"
                    ></video-player>

                    <div class="jiantou">
                        <img @click="IsShrink=!IsShrink" :src="IsShrink==true?require('../../static/jty.png'):require('../../static/jtz.png')" />
                    </div>
                </div>


                </a-layout-content>
                <a-layout-sider v-if="IsShrink" width="10.12rem"  >

                <div  class="bofanglist">

                    <h1 class="listtitle">
                        {{twoclassname=="undefined"?"":twoclassname}}
                    </h1>
                    <a-menu
                            style="height: 80%;padding-top: 0.63rem!important;margin-top: 0.5rem;"
                            :default-selected-keys="defaultkey"
                            :default-open-keys="['sub1']"
                            :mode="mode"
                            :theme="theme"
                    >
                        <a-menu-item style="font-size: 0.64rem;color:#FEFEFE; list-style: none;margin-left:20%;" v-for="item in data" :key="item.ID" @click="play(item.Num)">
                            <span class="bofang">播放</span>
                            {{item.Name}}
                        </a-menu-item>
                    </a-menu>
                </div>
                </a-layout-sider >
            </a-layout>
        </a-layout-content>

    </a-layout>
</template>

<script>
    import Header from '../home/components/header'
    import { getItem } from "@/utils/storage";
    export default {
        name: "VideoPlay",
        components:{
            Header
        },

        mounted(){
            //this.ukeyLoad();
            var video = this.$refs.videoDemo;


            if ("MediaSource" in window && MediaSource.isTypeSupported(this.mimeCodec)) {
                var mediaSource = new MediaSource();
                video.src = URL.createObjectURL(mediaSource);
                mediaSource.addEventListener("sourceopen", this.sourceOpen);
            } else {
                console.error("Unsupported MIME type or codec: ", this.mimeCodec);
            }

        },
        data(){
            return {
                IsShrink:true,
                assetURL:"",
                mimeCodec:'video/mp4; codecs="avc1.42E01E, mp4a.40.2"',
                mode: 'vertical',
                theme: 'light',
                // 视频播放
                playerOptions : {
                    playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
                    autoplay: false, //如果true,浏览器准备好时开始回放。
                    muted: false, // 默认情况下将会消除任何音频。
                    loop: false, // 导致视频一结束就重新开始。
                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language: 'zh-CN',
                    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                        type: "",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                        src: "" //url地址
                    }],
                    poster: "", //你的封面地址
                    // width: document.documentElement.clientWidth, //播放器宽度
                    notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    controlBar: {
                        timeDivider: true,
                        durationDisplay: true,
                        remainingTimeDisplay: false,
                        fullscreenToggle: true  //全屏按钮
                    }
                },

                queryParam:{
                    keywords:"",
                    page:1,
                    size:100,
                    oneclassid:0,
                    twoclassid:0,
                    un:getItem("un"),
                    mn:getItem("mn")
                },
                defaultkey:[0],
                data:[],
                num:'',
                oneclassid:'',
                twoclassid:'',
                twoclassname:''
            }
        },
        methods:{
            sourceOpen(){

                var mediaSource = this;
                var sourceBuffer = mediaSource.addSourceBuffer(mimeCodec);
                fetchAB(assetURL, function(buf) {
                    console.log(buf);
                    console.log(sourceBuffer);
                    sourceBuffer.addEventListener("updateend", function() {
                        console.log(mediaSource);
                        mediaSource.endOfStream();
                        //video.play();  //这里会报错就去掉了
                        console.log(mediaSource.readyState); // ended
                    });
                    sourceBuffer.appendBuffer(buf);
                });
            },
            fetchAB(url, cb){

                var xhr = new XMLHttpRequest();
                xhr.open("get", url);
                xhr.responseType = "arraybuffer";
                xhr.onload = function() {
                    console.log(xhr.response);
                    cb(xhr.response);
                };
                xhr.send();
            },
            onSearch(keyword){
                this.queryParam.keywords=keyword;
                this.GetPageList();
            },
             GetPageList(){
                let that=this;
                that.data=[];
                this.$axios.post("/api/Video/List", that.queryParam).then((res) => {
                    if (res.data.Message=="SUCCESS"){
                        var result=res.data.Data.VideoList;
                        for(let key  in result){
                            if(result[key].Num==that.num)
                            {
                                this.defaultkey[0]=result[key].ID;
                            }
                        }
                        that.data = result;
                        if(that.data==undefined)
                        that.data=[];

                       this.$nextTick(() => {
                            var menu=document.getElementsByClassName('ant-menu')[0];
                            var selectmenu=document.getElementsByClassName('ant-menu-item-selected');
                            if(selectmenu.length>0)
                            {
                                if(selectmenu[0].offsetTop>100)
                                    menu.scrollTop=selectmenu[0].offsetTop-90;
                            }
                       })
                    }
                });
            },
            play(num){
                let that=this;
                this.num=num;
                this.queryParam.oneclassid=this.$route.query.oneclassid;
                this.queryParam.twoclassid=this.$route.query.twoclassid;
                this.twoclassname=decodeURIComponent(this.$route.query.twoclassname);
                if(this.twoclassname==undefined){
                    this.twoclassname="";
                }
                this.$axios.post("/api/Video/Detail",{num:this.num}).then((res) => {
                if (res.data.Message=="SUCCESS"){
                    if(res.data.Data.Url=="")
                        that.$message.error("暂无此视频!");

                    that.playerOptions.sources[0].src=res.data.Data.Url;
                    that.playerOptions.poster=res.data.Data.Picture;



                }
                else
                {
                    that.$message.error(res.data.Data);
                }
            });
            }
        },
        created(){

            if(this.num=='')
                this.play(this.$route.query.num);
            this.GetPageList();
        },
    }

</script>
<style lang="less">
    /* 适配各种屏幕尺寸 */
    @media (min-width: 1024px){
        body,html{font-size: 18px!important;}
    }
    @media (min-width: 1100px) {
        body,html{font-size: 20px!important;}
    }
    @media (min-width: 1280px) {
        body,html{font-size: 22px!important;}
    }
    @media (min-width: 1366px) {
        body,html{font-size: 24px!important;}
    }
    @media (min-width: 1440px) {
        body,html{font-size: 25px!important;}
    }
    @media (min-width: 1680px) {
        body,html{font-size: 28px!important;}
    }
    @media (min-width: 1920px) {
        body,html{font-size: 33px!important;}
    }

</style>
<style scoped lang="less">
    #components-layout-demo-top-side .header{
        height: 2.76rem;
        background-color: #fff;
        box-shadow: 0px 0px 0.42rem 0px rgba(0, 0, 0, 0.18);


        box-sizing: border-box;
        padding: 0.63rem 1.78rem 0.6rem 1.7rem;
    }
    ::v-deep .ant-menu{
        background: transparent;
        overflow-y: auto;

    }
    ::v-deep .ant-menu::-webkit-scrollbar
    {
            width:0.12rem;
            height:0.12rem;
            background-color:transparent;
    }
    ::v-deep .ant-menu::-webkit-scrollbar-track
    {
             -webkit-box-shadow: inset 0 0 0.18rem #ffffff;
                border-radius: 0.303rem;
             background-color:transparent;
    }
    ::v-deep .ant-menu::-webkit-scrollbar-thumb
    {
             border-radius: 0.303rem;
             -webkit-box-shadow: inset 0 0 0.18rem #ffffff;
             background-color:#ffffff;
    }
    ::v-deep .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
        background: transparent;
        color: #C9151E!important;



    }
    ::v-deep .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .bofang{
        display: inline-block;

    }




    .listtitle{
        background: transparent;
        color: #fff;
        font-size: 0.76rem;
        text-align: center;
        margin-top: 0.69rem;
        margin-left: 0.5rem;
        height: 0.66rem;
        box-sizing: border-box;
    }
    .bofanglist{


        height: 100%;

        display: flex;
        flex-direction: column;
    }

    ::v-deep .ant-menu-item::after{
        border:none;

    }

    .bofang{
        background: linear-gradient(39deg, #CA1D1F, #E9363C);
        border-radius: 0.06rem;

        height: 0.48rem;
        line-height: 0.48rem;
        color: #FFFFFF;
        font-size: 0.39rem;
        display: none;

    }
    .jiantou{
        width: 0.51rem;
        height:0.87rem;
        z-index: 999;
        right: 0;
        top:40%;
        position: absolute;
        img{
            width: 100%;
            height: 100%;
        }
    }
    ::v-deep .ant-layout-sider{
        background: transparent;


    }

    ::v-deep .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
        border-right: 0;
    }

</style>
